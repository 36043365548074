import type { FC } from "react";
import React from "react";
import { useLocation } from "react-router-dom";
import useUserAuthStage from "src/state/hooks/useUserAuthStage";
import BiometricSetup from "src/ui/components/GlobalOverlays/Overlays/BiometricSetup";
import CallbackRequest from "../CallbackRequest/CallbackRequest";
import MandatoryMissingConsentsOverlay from "./Overlays/MandatoryMissingConsentsOverlay";
import RequestPushPermissions from "./Overlays/RequestPushPermissions";
import UnfinishedSignupOverlay from "./Overlays/UnfinishedSignupOverlay";

const GlobalOverlays: FC = () => {
  const location = useLocation();
  const isChatPath = location.pathname.includes("/chat");
  const isAppPath = location.pathname.startsWith("/app");
  const authStage = useUserAuthStage();

  return (
    <>
      <BiometricSetup />
      {authStage !== "noAuth" && (
        <>
          {isChatPath && <RequestPushPermissions />}
          {isAppPath && (
            <>
              <CallbackRequest view="dialog" />
              <UnfinishedSignupOverlay />
              <MandatoryMissingConsentsOverlay />
            </>
          )}
        </>
      )}
    </>
  );
};

export default GlobalOverlays;
