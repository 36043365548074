import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC } from "react";
import React, { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { APP_CONTENT_WIDTH_WITHOUT_PADDING } from "src/constants/layout";
import Path from "src/constants/path";
import { useBrowser } from "src/hybrid/components/Browser";
import { createAppPath } from "src/lib/createAppPath";
import createPath from "src/lib/createPath";
import { isHybridApp } from "src/lib/platform";
import translate from "src/lib/translate";
import SubscriptionCubit from "src/state/SubscriptionCubit/SubscriptionCubit";
import { TrackEvent } from "src/state/Track/TrackCubit";
import { tracker, useBloc } from "src/state/state";
import SignupCustomBloc from "src/ui/components/SignupCustomContent/state/SignupCustomBloc";
import Translate from "src/ui/components/Translate/Translate";

const StyledOverlay = styled.div`
  label: UnfinishedSignupOverlay;
  position: fixed;
  z-index: 999000;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition-delay: 0.6s;

  nine-bg {
    position: absolute;
    height: 100%;
  }

  &.show {
    pointer-events: all;
    transition-delay: 0;
    opacity: 1;
  }
`;

const UnfinishedSignupOverlay: FC = () => {
  const [show, setShow] = React.useState(false);
  const [
    { allSubscriptions },
    { hasEverHadSubscription, extractPayerInfo, hasInactiveSubscription }
  ] = useBloc(SubscriptionCubit);
  const navigate = useNavigate();
  const [browser, browserStatus] = useBrowser();
  const [targetPath, setTargetPath] = React.useState<string | undefined>();

  const renderSignupButtonText = useMemo(() => {
    return isHybridApp()
      ? translate("continue.signup.browser")
      : translate("continue.signup");
  }, []);

  const { lastSignupFunnel } = extractPayerInfo();
  const autoOpenEnabled = !isHybridApp();

  const handleContinueSignup = () => {
    const url = `/signup/${lastSignupFunnel}/${targetPath ?? ""}`;
    if (isHybridApp()) {
      void browser.open(createPath(url));
    } else {
      navigate(createPath(url));
    }
  };

  useEffect(() => {
    if (browserStatus === "finished") {
      // reload page
      window.location.reload();
    }
  }, [browserStatus]);

  useEffect(() => {
    // if a user has an inactive subscription, we don't want to show that they need to complete the signup
    if (lastSignupFunnel && !hasInactiveSubscription) {
      // find next uncompleted step
      const signupCubit = new SignupCustomBloc({
        parameters: {
          campaign: lastSignupFunnel
        },
        options: {
          disableInit: true
        }
      });

      void signupCubit.findFirstIncompleteStep(true).then((step) => {
        const foundCheckoutStep = step?.path === "checkout";
        if (foundCheckoutStep && hasEverHadSubscription) {
          setShow(false);
          return;
        }

        if (step) {
          setShow(true);
          setTargetPath(step.path);
        } else {
          // hide this overlay if no steps found
          setShow(false);
        }
      });
    }
  }, [
    hasEverHadSubscription,
    hasInactiveSubscription,
    allSubscriptions?.length
  ]);

  const goToLogin = () => {
    navigate(createAppPath(Path.authLogin, "auth"));
  };

  useEffect(() => {
    if (show && autoOpenEnabled) {
      handleContinueSignup();
      tracker.track(
        `Show Blocking Overlay, missing: ${targetPath}` as TrackEvent,
        {
          data: {
            lastSignupFunnel,
            targetPath
          }
        }
      );
    }
  }, [show]);

  return show ? (
    <StyledOverlay className={clsx("show")}>
      <nine-bg variant="animate-primary" slot="content">
        {!autoOpenEnabled && (
          <>
            <nine-navigation app variant="stepper"></nine-navigation>
            <nine-center>
              <nine-heading
                style={{
                  "--section-max-width": `${APP_CONTENT_WIDTH_WITHOUT_PADDING}px`
                }}
              >
                <h3 className="as-h4-large">
                  <Translate msg={"complete_signup_title"} />
                </h3>
                <nine-spacer s="md"></nine-spacer>
                <p className="m0 color-c-80">
                  <Translate msg={"complete_signup_subtitle"} />
                </p>
              </nine-heading>

              <nine-spacer s="lg"></nine-spacer>

              <div className="center">
                <nine-button
                  variant="fill"
                  color="black"
                  onClick={handleContinueSignup}
                >
                  {renderSignupButtonText}
                </nine-button>
                <nine-spacer s="sm"></nine-spacer>
                <nine-button variant="ghost" arrow="" onClick={goToLogin}>
                  <Translate msg="button.backToLogin" />
                </nine-button>
              </div>
            </nine-center>
          </>
        )}
      </nine-bg>
    </StyledOverlay>
  ) : null;
};

export default UnfinishedSignupOverlay;
