import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useState } from "react";

import PushNotificationsBloc from "src/hybrid/components/PushNotificationsControls";
import createTrackEvent from "src/lib/createTrackEvent";
import AppViewCubit from "src/state/AppViewCubit/AppViewCubit";
import { tracker, useBloc } from "src/state/state";
import UserCubit from "src/state/UserCubit/UserCubit";
import UserPreferencesCubit, {
  UserBucket,
  UserExclusiveBucketCategoryName,
  UserPreferenceKeys
} from "src/state/UserPreferencesCubit/UserPreferencesCubit";
import Translate from "src/ui/components/Translate/Translate";

const avatar01 =
  "https://cdn.sanity.io/images/g38rxyoc/production/e91edfdf1baf3a52b8ba13448ee605992314fc97-112x112.jpg";
const avatar02 =
  "https://cdn.sanity.io/images/g38rxyoc/production/235b659fcdf4120000de61e6e9b9a9f7be7f5b02-112x112.jpg";
const avatar03 =
  "https://cdn.sanity.io/images/g38rxyoc/production/46bbacbb3399885bae2a6440be9582e401e780e1-112x112.jpg";
const avatar04 =
  "https://cdn.sanity.io/images/g38rxyoc/production/a1cbe6f864cdb70c63b2e48aad93e2a6ab5af551-112x112.jpg";

const avatars = [avatar01, avatar02, avatar03, avatar04].sort(
  () => 0.5 - Math.random()
);

const StyledOverlay = styled.div`
  label: RequestPushPermissions;
  position: relative;
  z-index: 6000;

  nine-bg {
    height: 100vh;
    width: 100vw;
  }
`;

const RequestPushPermissions: FC = () => {
  const [show, setShow] = useState(false);
  const [requestPushStrategy, setRequestPushStrategy] =
    useState<UserBucket | null>(null);
  const [{ suspendUi }] = useBloc(UserCubit);
  const [userPref, { updateUserPreferences, selectBucketForCategory }] =
    useBloc(UserPreferencesCubit);
  const [pushState, { requestPermissions, checkPermissions, isAvailable }] =
    useBloc(PushNotificationsBloc);
  const [{ messagesLength = 0, lastMessageIsFromUser = false }] =
    useBloc(AppViewCubit);

  const aggressive = requestPushStrategy === UserBucket.PushDialogAggro;

  useEffect(() => {
    void selectBucketForCategory(
      UserExclusiveBucketCategoryName.PushDialog
    ).then((e) => setRequestPushStrategy(e));
  }, []);

  const handlePromptNow = (): void => {
    tracker.track(createTrackEvent("Push Notification Access - Prompt"));
    void requestPermissions({ goToSettings: true });
  };

  const handleDismiss = (): void => {
    setShow(false);
    void updateUserPreferences({
      [UserPreferenceKeys.appUserPushNotificationAccessRequestAction]: "not-now"
    });
  };

  const hasDismissed = Boolean(
    userPref[UserPreferenceKeys.appUserPushNotificationAccessRequestAction] ===
      "not-now"
  );

  useEffect(() => {
    // Initial check
    if (!suspendUi) {
      void checkPermissions();
    }
  }, [suspendUi]);

  // handle logic how and when to show the prompt
  useEffect(() => {
    let mounted = true;

    // do nothing if there are no messages loaded yet, we expect there always to bt at least one message (the welcome message)
    if (messagesLength === 0 || hasDismissed) {
      setShow(false);
      return;
    }

    // handle state change, we can prompt the user
    if (
      isAvailable &&
      lastMessageIsFromUser &&
      pushState.permissions?.receive !== "granted"
    ) {
      setTimeout(() => {
        if (mounted) {
          setShow(true);
        }
      }, 1500);
    } else {
      // hide the prompt if the user has granted or denied permission
      setShow(false);
    }

    return () => {
      mounted = false;
    };
  }, [
    pushState,
    messagesLength,
    lastMessageIsFromUser,
    hasDismissed,
    isAvailable
  ]);

  useEffect(() => {
    // handle aggressive prompt version
    if (aggressive && show) {
      void requestPermissions({ goToSettings: false });
    }

    if (show) {
      tracker.track(createTrackEvent("Push Notification Access - Initiated"), {
        data: {
          "Push Notification Bucket": aggressive
            ? UserBucket.PushDialogAggro
            : UserBucket.PushDialogSoft
        }
      });
    }
  }, [show, aggressive]);

  useEffect(() => {
    if (pushState.permissions?.receive === "granted") {
      setShow(false);
    }
  }, [pushState]);

  // Render nothing for aggressive version
  if (aggressive || !isAvailable) {
    return null;
  }

  return (
    <StyledOverlay>
      <nine-overlay
        variant="fullpage-content"
        overlayOpen={show ? "true" : "false"}
        hide-close
      >
        <nine-bg variant="animate-primary" slot="content">
          <nine-center
            style={{ padding: "var(--space-lg)", textAlign: "center" }}
          >
            <nine-avatar-list>
              {avatars.map((img) => (
                <nine-avatar key={img} img={img}></nine-avatar>
              ))}
            </nine-avatar-list>
            <h4>
              <Translate msg="notification.careTeamReplies" />
            </h4>
            <nine-spacer s="md"></nine-spacer>
            <nine-button color="black" onClick={handlePromptNow}>
              <Translate msg="notification.action" />
            </nine-button>
            <nine-spacer s="sm"></nine-spacer>
            <nine-button variant="ghost" arrow="" onClick={handleDismiss}>
              <Translate msg="notNow" />
            </nine-button>
          </nine-center>
        </nine-bg>
      </nine-overlay>
    </StyledOverlay>
  );
};

export default RequestPushPermissions;
